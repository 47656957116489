const initialState = {
  suggestions: {
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      total: 0,
    },
    query: {},
    uuid: '',
  },
  generalMessage: '',
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_STATE_SUGGESTIONS(state, { type, value }) {
    state[type] = value
  },
  DELETE_ELEMENT(state, id) {
    const indexDelete = state.suggestions.data.findIndex(
      (item) => item.id === id
    )

    if (indexDelete >= 0) {
      state.suggestions.data.splice(indexDelete, 1)
      state.suggestions.pagination.total -= 1
    }
  },
}

export const actions = {
  async fetchSuggestions({ commit }, payload) {
    const { offerId, params } = payload

    const response =
      await this.$repository.v3.partnership.offers.getOffersSuggestions(
        offerId,
        params
      )

    if (!response.success) {
      commit('SET_STATE_SUGGESTIONS', {
        type: 'suggestions',
        value: { ...initialState.suggestions },
      })
    }

    if (!response.result) {
      commit('SET_STATE_SUGGESTIONS', {
        type: 'generalMessage',
        value: response?.message,
      })
    }

    if (response.result) {
      const data = {
        data: response.result.match_users.data,
        pagination: response.result.match_users,
        uuid: response.result.uuid,
      }

      commit('SET_STATE_SUGGESTIONS', {
        type: 'suggestions',
        value: data,
      })
    }

    return response
  },
  async getQtyRevisedSuggestions({ commit }, payload) {
    const { offerId } = payload

    const response =
      await this.$repository.v3.partnership.offers.getQtyRevisedSuggestions(
        offerId
      )

    return response
  },
  async fetchCheckIfSearchToolIsActiveForOffer({ commit }, payload) {
    const { offerId } = payload

    const response =
      await this.$repository.v3.partnership.offers.getCheckIfSearchToolIsActiveForOffer(
        offerId
      )

    return response
  },
}
