import { useRequestOffice } from '~/repositories'
const rootPath = 'api/v3/partnership/work-offers'

export default () => ({
  /* OFERTAS */
  getWorkOffers(params) {
    return useRequestOffice('get', `${rootPath}`, { params })
  },
  /* Detalle de una Oferta */
  getWorkOfferById(id) {
    return useRequestOffice('get', `${rootPath}/${id}`)
  },
  putOfferById(id, body) {
    return useRequestOffice('put', `${rootPath}/${id}`, body)
  },
  /* Kanban de candidatos */
  getOffersKanban(offerId, params) {
    return useRequestOffice(
      'get',
      `/${rootPath}/${offerId}/match-users/kanban`,
      { params }
    )
  },
  getOffersColumnKanban(offerId, params) {
    return useRequestOffice(
      'get',
      `/${rootPath}/${offerId}/match-users/kanban/show`,
      { params }
    )
  },
  getOffersSuggestions(offerId, params) {
    return useRequestOffice(
      'get',
      `/${rootPath}/${offerId}/match-users/ai-suggestions`,
      params
    )
  },
  getQtyRevisedSuggestions(offerId) {
    return useRequestOffice(
      'get',
      `/${rootPath}/${offerId}/match-users/qty-revised-ai-suggestions`
    )
  },
  getCheckIfSearchToolIsActiveForOffer(offerId) {
    return useRequestOffice(
      'get',
      `/${rootPath}/${offerId}/check-if-search-tool-is-active`
    )
  },
  /* Agregados como candidatos */
  getMatchUserWorkOffersById(id, params) {
    return useRequestOffice('get', `${rootPath}/${id}/match-user-work-offers`, {
      params,
    })
  },
  getUserInterviews(idOffer, idUser) {
    return useRequestOffice(
      'get',
      `${rootPath}/${idOffer}/match-user-work-offers/match-user/${idUser}/business-interviews`
    )
  },
  getReferences(offerId, userId) {
    return useRequestOffice(
      'get',
      `${rootPath}/${offerId}/match-user-work-offers/match-user/${userId}/references`
    )
  },
  getAffinity(offerId, userId) {
    return useRequestOffice(
      'get',
      `${rootPath}/${offerId}/match-user-work-offers/match-user/${userId}/affinity`
    )
  },
  /* Talentos sugeridos por afinidad */
  putStatusCandidate(id, idCandidate, params) {
    return useRequestOffice(
      'put',
      `${rootPath}/${id}/match-user-work-offers/match-user/${idCandidate}/status`,
      params
    )
  },
  postOfferById(body) {
    return useRequestOffice('post', `${rootPath}`, body)
  },
  putUpdateStatus(id, body) {
    return useRequestOffice('put', `${rootPath}/${id}/change-status`, body)
  },
  /* Clonar oferta */
  postCloneOffer(id) {
    return useRequestOffice('post', `${rootPath}/${id}/clone`)
  },
  /* Tab de Perfil profesional para editar oferta */
  putUpdateTabProfesionalProfile(id, body) {
    return useRequestOffice(
      'put',
      `${rootPath}/${id}/professional-profile`,
      body
    )
  },
  /* Tab de Perfil de empresa para editar oferta */
  putUpdateTabCompanyProfile(id, body) {
    return useRequestOffice('put', `${rootPath}/${id}/company-profile`, body)
  },

  /* Agregar y eliminar Candidatos para el Drag and drop */
  postDiscardMatchUserWorkOffers(idOffer, idMatchUser, body = {}) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idOffer}/match-user-work-offers/match-user/${idMatchUser}/discard`,
      body
    )
  },
  postRestoreMatchUserWorkOffers(idOffer, idMatchUser) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idOffer}/match-user-work-offers/match-user/${idMatchUser}/restore`
    )
  },
  postMatchUserWorkOffers(idOffer, idMatchUser, body = {}) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idOffer}/match-user-work-offers/match-user/${idMatchUser}`,
      body
    )
  },
  deleteMatchUserWorkOffers(idOffer, idMatchUser) {
    return useRequestOffice(
      'delete',
      `${rootPath}/${idOffer}/match-user-work-offers/match-user/${idMatchUser}`
    )
  },
  postMatchSendCompany(offerId, userId, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${offerId}/match-user-work-offers/match-user/${userId}/send-to-company`,
      body
    )
  },
  postMatchUserMoveHired(offerId, userId, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${offerId}/match-user-work-offers/match-user/${userId}/hire`,
      body
    )
  },

  /* Obtener lista de descartados */
  getDiscardMatchUserWorkOffers(idOffer, params) {
    return useRequestOffice(
      'get',
      `${rootPath}/${idOffer}/match-users/discard`,
      { params }
    )
  },

  /* Obtener lista de rechazados */
  getRejectedMatchUserWorkOffers(idOffer, params) {
    return useRequestOffice(
      'get',
      `${rootPath}/${idOffer}/match-users/reject`,
      {
        params,
      }
    )
  },

  /* Restaurar un candidato rechazado */
  postRestoreCandidaterWorkOffers(idOffer, idMatchUser) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idOffer}/match-user-work-offers/match-user/${idMatchUser}/restore-from-rejected`
    )
  },

  /* Cerrar oferta */
  postCloseOffers(idOffer, body) {
    return useRequestOffice('post', `${rootPath}/${idOffer}/close`, body)
  },

  /* Entrevista */
  postInterview(idOffer, idUser, params) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idOffer}/match-users/${idUser}/interviews`,
      params
    )
  },
  /* Placed */
  postPlacementInfo(idOffer, userId, body) {
    return useRequestOffice(
      'post',
      `${rootPath}/${idOffer}/match-users/${userId}/placement-info`,
      body
    )
  },
})
